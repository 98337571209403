import React from 'react';
import _ from 'lodash';

import components, {Layout} from '../components/index';

export default class Network extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <components.ValueAdd {...this.props}/>
      </Layout>
    );
  }
}
